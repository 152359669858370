export default [
    {
        title: 'Аналитика',
        route: 'dashboard',
        icon: 'TrendingUpIcon',
    },
    {
        title: 'Справочники',
        icon: 'BookOpenIcon',
        children: [
            {
                title: 'Бренды',
                route: 'brands',
            },
            {
                title: 'Внешние справочники',
                route: 'external-options',
            },
            {
                title: 'Размеры',
                route: 'sizes',
            },
            {
                title: 'Категории',
                route: 'category-list',
                icon: 'MenuIcon',
                action: 'read',
                resource: 'categories',
            },
        ],
    },
    {
        title: 'Товары',
        route: 'products',
        icon: 'TagIcon',
        action: 'read',
        resource: 'products',
    },
    {
        title: 'Приходы',
        route: 'arrivals',
        icon: 'CornerDownRightIcon',
        action: 'read',
        resource: 'arrivals',
    },
    {
        title: 'Заказы',
        route: 'order-list',
        icon: 'FileTextIcon',
        action: 'read',
        resource: 'orders',
    },
    {
        title: 'Возвраты',
        route: 'returns',
        icon: 'CornerDownLeftIcon',
        action: 'read',
        resource: 'returns',
    },
    {
        title: 'Деньги',
        icon: 'DollarSignIcon',
        action: 'read',
        resource: 'payments',
        children: [
            {
                title: 'Оплаты',
                route: 'payment-list',
                icon: 'DollarSignIcon',
                action: 'read',
                resource: 'payments',
            },
            {
                title: 'Затраты',
                route: 'expense-list',
                icon: 'Trash2Icon',
                action: 'read',
                resource: 'expenses',
            },
            {
                title: 'Кассы',
                route: 'bank-accounts',
                icon: 'ArchiveIcon',
                action: 'read',
                resource: 'bank-accounts',
            },
            {
                title: 'Взаиморасчеты',
                route: 'settlements',
                icon: 'LinkIcon',
                action: 'read',
                resource: 'users',
            },
        ],
    },
    {
        title: 'Пользователи',
        route: 'user-list',
        icon: 'UserIcon',
        action: 'read',
        resource: 'users',
    },
    {
        title: 'Чаты',
        route: 'chat',
        icon: 'MessageSquareIcon',
        tagVariant: 'info',
        action: 'read',
        resource: 'chat',
    },
    {
        title: 'Движения Товаров',
        route: 'history',
        icon: 'RepeatIcon',
        action: 'read',
        resource: 'history',
    },
    {
        title: 'Импорт Товаров',
        route: 'import',
        icon: 'DownloadIcon',
        action: 'read',
        resource: 'import',
    },
    {
        title: 'Товарные остатки',
        route: 'remains',
        icon: 'PackageIcon',
        action: 'read',
        resource: 'remains',
    },
    {
        title: 'Инвентаризация',
        route: 'inventory',
        icon: 'ServerIcon',
        action: 'read',
        resource: 'inventory',
    },
    {
        title: 'Прайс-лист',
        route: 'price-list',
        icon: 'ActivityIcon',
        action: 'read',
        resource: 'prices',
    },
    {
        title: 'Email рассылки',
        route: 'email-templates',
        icon: 'MailIcon',
        action: 'read',
        resource: 'emails',
    },
    {
        title: 'Оборотка',
        route: 'turnover',
        icon: 'BarChart2Icon',
        action: 'read',
        resource: 'turnover',
    },
];

<template>
  <b-navbar-nav class="nav">
    <b-nav-item id="chat" :to="{ name: 'chat' }">
      <feather-icon icon="MessageSquareIcon" size="21"/>
      <b-tooltip triggers="hover" target="chat" title="Chat" :delay="{ show: 1000, hide: 50 }"/>
    </b-nav-item>
  </b-navbar-nav>
</template>

<script>
import {
  BNavbarNav, BNavItem, BTooltip,
} from 'bootstrap-vue';

export default {
  name: 'Bookmarks',
  components: {
    BNavbarNav, BNavItem, BTooltip,
  },
};
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

ul
{
  list-style: none;
  padding: 0;
  margin: 0;
}
p {
  margin: 0;
}

.nav-bookmar-content-overlay {
    position: fixed;
    opacity: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-transition: all 0.7s;
    transition: all 0.7s;
    z-index: -1;

    &:not(.show) {
      pointer-events: none;
    }

    &.show {
      cursor: pointer;
      z-index: 10;
      opacity: 1;
    }
}
</style>

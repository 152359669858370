<template>
  <div class="navbar-container d-flex content align-items-center">
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21"/>
        </b-link>
      </li>
    </ul>
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <bookmarks />
    </div>
    <b-navbar-nav class="nav align-items-center ml-auto">
      <dark-toggler class="d-none d-lg-block" />
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav,
} from 'bootstrap-vue';
import Bookmarks from '@/views/components/Bookmarks.vue';
import DarkToggler from '@/views/components/DarkToggler.vue';
import NotificationDropdown from './components/NotificationDropdown.vue';
import UserDropdown from '@/views/components/UserDropdown.vue';

export default {
  components: {
    BLink,
    BNavbarNav,
    Bookmarks,
    DarkToggler,
    NotificationDropdown,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
};
</script>
